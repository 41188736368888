'use client';

import {ConnectButton} from "../auth/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHippo } from "@fortawesome/free-solid-svg-icons";
import { useWeb3Auth } from "../../services/auth/web3auth";
import { useEffect, useState } from "react";

export function NavbarComponentBigScreen() {
  return (
    // rounded on the below side not above
    <nav class="bg-white border-gray-200 dark:bg-gray-900 rounded-sm">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="https://flowbite.com/" class="flex items-center space-x-3 rtl:space-x-reverse">
            {/* <img src="https://flowbite.com/docs/images/logo.svg" class="h-8" alt="Flowbite Logo" /> */}
            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">ChatBot</span>
        </a>
        {/* <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
        </button> */}
        <div class="" id="navbar-default">
        {/* <ConnectButton/> */}

        {/* <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
            <a href="#" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Home</a>
            </li>
            <li>
            <a href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a>
            </li>
            <li>
            <a href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Services</a>
            </li>
            <li>
            <a href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Pricing</a>
            </li>
            <li>
            <a href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
            </li>
        </ul> */}
        </div>
    </div>
    </nav>

  );
}

export function NavBarAuth(){
    return(
        // nav with black bg and top left corner text with chatbot and white text
        <nav class="bg-black">
            <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="https://flowbite.com/" class="flex items-center space-x-3 rtl:space-x-reverse">
                    {/* <img src="https://flowbite.com/docs/images/logo.svg" class="h-8" alt="Flowbite Logo" /> */}
                    <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">ChatBot</span>
                </a>
            </div>
        </nav>
    )
}

export function SideNavBar(){
    const {logout,connected} = useWeb3Auth();
    const [theme, setTheme] = useState('dark');
    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
      };

    useEffect(() => {
        document.body.dataset.theme = theme;
      }, [theme]);
    
    const changeMainContentMargin = () => {
        // if width is less than 600px then dont change the margin
        if(window.innerWidth < 600){
            return;
        }
        let mc = document.getElementById("main-content");
        if (!mc ) return;
        mc.style.marginLeft = "calc(13rem + 1rem)";
    }
    const changeMainContentMarginBack = () => {
        // if width is less than 600px then dont change the margin
        if(window.innerWidth < 600){
            return;
        }
        let mc = document.getElementById("main-content");
        if (!mc ) return;
        mc.style.marginLeft = "calc(5rem)";
    }

    return(
        <nav className="navbar" onMouseOver={changeMainContentMargin} onMouseOut={changeMainContentMarginBack}>
            {/* place a logo */}
            {/* <div className="logo-header bg-white">
                Here
                <FontAwesomeIcon icon={faHippo}/>
            </div> */}
            <ul class="navbar-nav">
                <li class="logo">
                    <a href="#" class="nav-link">
                    {/* <span class="link-text logo-text">ChatBot</span> */}
                    <span className="link-text logo-text">
                    &nbsp;ChatBot
                    </span>
                    {/* <span>CB</span> */}
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fad"
                        data-icon="angle-double-right"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                    >
                        <g class="fa-group">
                        <path
                            fill="currentColor"
                            d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                            class="fa-secondary"
                        ></path>
                        <path
                            fill="currentColor"
                            d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                            class="fa-primary"
                        ></path>
                        </g>
                    </svg>
                    </a>
                </li>

                <li class="nav-item">
                    <a href="#" class="nav-link">
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fad"
                        data-icon="comment-alt"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        class="svg-inline--fa fa-comment-alt fa-w-16 fa-5x"
                        >
                        <g class="fa-group">
                            <path
                            fill="currentColor"
                            d="M448,0H64A64,64,0,0,0,0,64V288a64,64,0,0,0,64,64H192v76a12,12,0,0,0,20.39,9.31L304,352H448a64,64,0,0,0,64-64V64A64,64,0,0,0,448,0Z"
                            class={theme === 'dark'?"fa-primary":"fa-primary"}
                            ></path>

                        </g>
                    </svg>

                    <span class="link-text">Chat</span>
                    </a>
                </li>
                {connected?
                <li class="nav-item" onClick={logout}>
                    <a class="nav-link">
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fad"
                        data-icon="alien-monster"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        class="svg-inline--fa fa-alien-monster fa-w-18 fa-9x"
                    >
                        <g class="fa-group">
                        <path
                            fill="currentColor"
                            d="M560,128H528a15.99954,15.99954,0,0,0-16,16v80H480V176a15.99954,15.99954,0,0,0-16-16H416V96h48a16.00079,16.00079,0,0,0,16-16V48a15.99954,15.99954,0,0,0-16-16H432a15.99954,15.99954,0,0,0-16,16V64H368a15.99954,15.99954,0,0,0-16,16v48H224V80a15.99954,15.99954,0,0,0-16-16H160V48a15.99954,15.99954,0,0,0-16-16H112A15.99954,15.99954,0,0,0,96,48V80a16.00079,16.00079,0,0,0,16,16h48v64H112a15.99954,15.99954,0,0,0-16,16v48H64V144a15.99954,15.99954,0,0,0-16-16H16A15.99954,15.99954,0,0,0,0,144V272a16.00079,16.00079,0,0,0,16,16H64v80a16.00079,16.00079,0,0,0,16,16h48v80a16.00079,16.00079,0,0,0,16,16h96a16.00079,16.00079,0,0,0,16-16V432a15.99954,15.99954,0,0,0-16-16H192V384H384v32H336a15.99954,15.99954,0,0,0-16,16v32a16.00079,16.00079,0,0,0,16,16h96a16.00079,16.00079,0,0,0,16-16V384h48a16.00079,16.00079,0,0,0,16-16V288h48a16.00079,16.00079,0,0,0,16-16V144A15.99954,15.99954,0,0,0,560,128ZM224,320H160V224h64Zm192,0H352V224h64Z"
                            class="fa-primary"
                        ></path>
                        <path
                            fill="currentColor"
                            d="M160,320h64V224H160Zm192-96v96h64V224Z"
                            class=" fa-secondary "
                        ></path>
                        </g>
                    </svg>
                    <span class="link-text">Logout</span>
                    </a>
                </li>:<></>}


                <li class="nav-item" id="themeButton" onClick={toggleTheme}>
                    <a class="nav-link">
                    <svg
                        class="theme-icon"
                        id="solarIcon"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fad"
                        data-icon="sun"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="svg-inline--fa fa-sun fa-w-16 fa-7x"
                    >
                        <g class="fa-group">
                        <path
                            fill="currentColor"
                            d="M502.42 240.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.41-94.8a17.31 17.31 0 0 0-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4a17.31 17.31 0 0 0 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.41-33.5 47.3 94.7a17.31 17.31 0 0 0 31 0l47.31-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3a17.33 17.33 0 0 0 .2-31.1zm-155.9 106c-49.91 49.9-131.11 49.9-181 0a128.13 128.13 0 0 1 0-181c49.9-49.9 131.1-49.9 181 0a128.13 128.13 0 0 1 0 181z"
                            class=" fa-primary"
                        ></path>
                        <path
                            fill="currentColor"
                            d="M352 256a96 96 0 1 1-96-96 96.15 96.15 0 0 1 96 96z"
                            class="fa-secondary"
                        ></path>
                        </g>
                    </svg>
                    <span class="link-text">Themify</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export const NavBarRenderer = () =>{
    // return (config.auth?NavBarAuth():NavbarComponentBigScreen());
    return (SideNavBar());
}

