import {NavBarRenderer} from "../navbar/navbar";
import {ConnectButton} from "./auth";
export function AuthPage(props) {
  return (
    <div>
        <NavBarRenderer auth={true}/>
        <div className="flex flex-col items-center justify-center h-screen text-white">
        <h1 className="text-xl font-bold message-color">
            Get Started
        </h1>
        <span><br/></span>
        {/* <ConnectWeb3AuthButton /> */}
        <ConnectButton props={props}/>
        </div>
    </div>
    
  );
}