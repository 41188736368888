import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {Home} from "./pages/home";  
import { AuthPage } from './components/auth/authPage';
import { Web3AuthProvider } from "./services/auth/web3auth";

function App() {
  return (
    <div className="App">
      <Web3AuthProvider>
       <BrowserRouter>
       <Routes>
        {/* props send authHandler */}
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/myaccount"  />
       </Routes>
       </BrowserRouter>
       </Web3AuthProvider>
    </div>
  );
}

export default App;
