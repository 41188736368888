import { toast } from "react-toastify";
import { API_URI } from "../../config/consts";

export class APIHandler {
  constructor() {
    this.API_URI = API_URI;
  }

  async createUser(username, email, address) {
    let token = localStorage.getItem("awt");
    if (token && token != "null" && token != "undefined") {
      // Already logged in
      return;
    }
    try {
      const res = await fetch(`${API_URI}/create-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          email,
          address,
        }),
      });

      // Check if the response is OK (status code 200)
      if (res.ok) {
        const token = await res.json()
        localStorage.setItem("awt", token.token);
        return;
      } else {
        toast.error("Error creating user");
        return null;
      }
    } catch (error) {
      console.error("Error creating user:", error);
      toast.error("Error creating user");
      return null;
    }
  }

  async getUserInfo(address) {
    try {
      const res = await fetch(`${API_URI}/user/${address}`);

      // Check if the response is OK (status code 200)
      if (res.ok) {
        const user = await res.json(); // Parse JSON response
        return user;
      } else {
        toast.error("Error getting user info");
        return null;
      }
    } catch (error) {
      console.error("Error getting user info:", error);
      toast.error("Error getting user info");
      return null;
    }
  }

  async getMessagesByAddress(address, offset, limit) {
    let token = localStorage.getItem("awt");
    let headers = {
      'Authorization': `Bearer ${token}`
    }
    try {
      const res = await fetch(`${API_URI}/messages/address/${address}?offset=${offset}&limit=${limit}`, {
          headers      
      });
      if (res.ok) {
        const messages = await res.json();
        return messages;
      } else {
        toast.error("Error getting messages");
        return null;
      }
    } catch (error) {
      console.error("Error getting messages:", error);
      toast.error("Error getting messages");
      return null;
    }
  }

  async askQuestion(address, question) {
    let token = localStorage.getItem("awt");
    try {
      
      const res = await fetch(`${API_URI}/ask`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          address,
          question,
        }),
      });

      // Check if the response is OK (status code 200)
      if (res.ok) {
        const response = await res.json(); // Parse JSON response
        return response;
      } else {
        toast.error("Error asking the question");
        return null;
      }
    } catch (error) {
      console.error("Error asking the question:", error);
      toast.error("Error asking the question");
      return null;
    }
  }
}

export default new APIHandler();
