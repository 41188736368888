import { Component,useState } from "react";
import { Web3Auth } from "@web3auth/modal";
import { useWeb3Auth } from "../../services/auth/web3auth";

export function ConnectButton() {
  const { connected, login } = useWeb3Auth();
  return (
    <button onClick={login} className="flex flex-row rounded-full px-20 py-3 text-white justify-center align-center cursor-pointer bg-orange-600">Connect</button>
  );
}

