import { faArrowDown, faMicrophone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useWeb3Auth } from '../../services/auth/web3auth';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useSpeechSynthesis } from "react-speech-kit";

const ChatContainer = styled.div`
  flex: 1;
  background-color: var(--chat-container-bg);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 96vh;
  align-items: center;
  position: relative;
  -ms-overflow-style: none; 
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 600px){
    padding-bottom: 4rem; /* Add padding at the bottom */
  }
`;




const ChatBox = styled.div`
  overflow-y: auto;
  max-height: 80vh;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  &:not(:hover) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
`;


const Message = styled.div`
  background-color: ${(props) => (props.sender === 'user' ? 'var(--user-message-bg)' : 'var(--bot-message-bg)')};
  align-self: ${(props) => (props.sender === 'user' ? 'flex-end' : 'flex-start')};
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: ${(props) => (props.sender === 'user' ? 'auto' : '0')};
  margin-right: ${(props) => (props.sender === 'user' ? '0' : 'auto')};
  max-width: 60%; /* Adjust the max width for messages */
  word-wrap: break-word;
  border: 1px solid #ddd;
  color: var(--message-color);
  display: flex; /* Use flexbox to align content and icon */
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Center items vertically */
  justify-content: space-between;
  
  max-width: 60%; /* Adjust the max width for messages */
  
  @media (max-width: 600px) { /* Adjust the max-width value as needed */
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%; /* Increase max width for smaller screens */
    
  }
`;

const SenderIndicator = styled.span`
  font-size: 0.75rem;
  color: var(--indicator-color);
  padding-right: 0.5rem;
  align-self: flex-end;
`;

const ReadAloudIcon = styled(FontAwesomeIcon)`
  color: #6c757d; /* or any color you want for the icon */
  cursor: pointer;
  margin-left: 8px; /* Adjust spacing to the left of the icon */
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-secondary);
  border: 1px solid #ddd;
  border-radius: 1rem;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  @media only screen and (max-width: 600px) {
    margin-top: auto;
    min-height: 50px;
    padding: 0.5rem 1rem; // Ensure the horizontal padding matches the one above.
  }
`;


const InputField = styled.textarea`
  flex-grow: 1; // Make input field grow to fill space
  margin: 0 0.5rem; // Add margin to both sides to ensure it doesn't touch the icons
  border: none;
  outline: none;
  background: transparent;
  padding: 0.25rem 0.5rem; // Adjusted padding
  color: var(--input-color);
  resize: none;
`;

// Ensure buttons don't have additional margin that could push the input field
const AudioButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0.5rem;
`;

const SendButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0.5rem;
`;

const ScrollToBottomButton = styled.button`
  position: absolute; // Maintain absolute positioning
  bottom: 12vh; // Place it above the InputBox, adjust this value as needed
  right: 10px; // Place it to the right
  background-color: orange;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #FFA500;
  }
  @media only screen and (max-width: 600px){
    bottom: 0rem; /* Add padding at the bottom */
  }
`;


export function ChatComponent() {
  const [Imessages, setIMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [inReq, setInReq] = useState(false);
  const chatBoxRef = useRef(null);
  const {connected,askQuestion,messages} = useWeb3Auth();
  const { speak } = useSpeechSynthesis();

  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    resetTranscript
  } = useSpeechRecognition();
  const startListening = () => SpeechRecognition.startListening({ continuous: true });
  const stopListening = () => SpeechRecognition.stopListening();

  const processMessages = (messages) => {
    let newMessages = [];
    for (let i = 0; i < messages.length; i++) {
      let message = messages[i];
      let question = message.question;
      let answer = message.answer;
      if (question) {
        const userMessage = {
          text: question,
          sender: 'user',
        };
        newMessages.push(userMessage);
      }
      if (answer) {
        const botMessage = {
          text: answer,
          sender: 'bot',
        };
        newMessages.push(botMessage);
      }

    }
    return newMessages;
  };

  useEffect(() => {
    if (connected) {
      document.getElementById("loading_mess").style.display = "none"; 
      setIMessages(processMessages(messages));
      }
  } , [messages]);

  useEffect(() => {
    if (Imessages.length > 0) {
      scrollToBottom();
    }
  }, [Imessages]);

  useEffect(() => {
    if (!listening && transcript !== '') {
      setInputText(transcript);
    }

  }, [listening, transcript]);

  

  const scrollToBottom = () => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  };
  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSendMessage = async (text) => {
    if (inReq) return;
    if (!text) text = inputText;
    if (text === '' || text === null) return;
    setInReq(true);
      const newMessage = {
        text: inputText,
        sender: 'user', 
      };
      let newMessages = [...Imessages, newMessage];
      setIMessages(newMessages);
      setInputText('');
      let resp = await askQuestion(inputText);
      console.log(resp);
      if (resp) {
        const botMessage = {
          text: resp.content,
          sender: 'bot',
        };
        let newMessages1 = [...newMessages,botMessage];
        setIMessages(newMessages1);
      }
      resetTranscript();
      setInReq(false);
  };

  const handleMicrophoneClick = () => {
    if (!browserSupportsSpeechRecognition) {
      alert("Your browser doesn't support speech recognition");
      return;
    }
    if (listening) {
      stopListening();
      console.log("Stopped listening");
    } else {
      startListening({ continuous: true });
      console.log("Listening...");
    }
  };

  const handleScroll = () => {
    const isAtBottom = chatBoxRef.current.scrollHeight - chatBoxRef.current.scrollTop === chatBoxRef.current.clientHeight;
    setShowScrollButton(!isAtBottom);
  };

  const extractTextFromHtml = (htmlString) => {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = htmlString;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  };

  return (
    <main id='main-content'>
      <ChatContainer>
      <ChatBox ref={chatBoxRef} onScroll={handleScroll}>
        {Imessages.length === 0 && <div class="lds-ellipsis place-items-center" id="loading_mess"><div></div><div></div><div></div><div></div></div>}
        {Imessages.map((message, index) => (
  <Message key={index} sender={message.sender}>
    <SenderIndicator>{message.sender === 'user' ? 'You' : 'ChatBot'}</SenderIndicator>
    <div dangerouslySetInnerHTML={{__html: message.text}}></div>
    {message.sender !== 'user' && (
      <ReadAloudIcon 
        icon={faMicrophone} 
        onClick={() => speak({ text: extractTextFromHtml(message.text) })} 
      />
    )}
  </Message>
))}
        {
          listening && <Message sender='user'>
            <SenderIndicator>You</SenderIndicator>
            <div>Listening... <div class="lds-ellipsis place-items-center "><div></div><div></div><div></div><div></div></div></div>
          </Message>
        }

        {
          inReq && <Message sender='bot'>
            {/* <SenderIndicator>You</SenderIndicator> */}
            <div class="lds-ellipsis place-items-center "><div></div><div></div><div></div><div></div></div>
          </Message>
        }

      </ChatBox>
      {showScrollButton &&
  <ScrollToBottomButton onClick={scrollToBottom}>
    <FontAwesomeIcon icon={faArrowDown} color='white'/>
  </ScrollToBottomButton>}
        <InputBox>
          <AudioButton onClick={handleMicrophoneClick}>
            <FontAwesomeIcon icon={faMicrophone} color={listening?'orange':'black'}/>
          </AudioButton>
          <InputField
            type="textarea"
            placeholder={listening?"Recording your message":"Type your message..."}
            value={inputText}
            onChange={handleInputChange}
          />
          <SendButton onClick={handleSendMessage}><FontAwesomeIcon icon={faPaperPlane} size='lg' style={{color: '#FFA500'}}/></SendButton>
        </InputBox>
      </ChatContainer>
    </main>
  );
}
