import { useEffect, useState } from "react";
import { AuthHelper } from "../components/auth/auth";
import { NavBarRenderer } from "../components/navbar/navbar";
import { useWeb3Auth } from "../services/auth/web3auth";
import { AuthPage } from "../components/auth/authPage";
import { ChatComponent } from "../components/chat/chat";
import { APIHandler } from "../services/api/ask";
import styled from "styled-components";
import "../css/navbar.css"
import "../css/main.css";
import "../css/animation.css";


const Container = styled.div`
  display: flex;
  height: 100vh; /* Set the container height to full viewport height */
`;


export function Home(props) {
  const {connected,address} = useWeb3Auth();
  let localUser = localStorage.getItem("awt");
  let isConnected = (localUser != "null" && localUser != null && localUser != undefined && localUser != "undefined" && localUser) || connected;
  useEffect(() => {
    localUser = localStorage.getItem("awt");
    isConnected = (localUser != "null" && localUser != null && localUser != undefined) || connected;
  }, [connected,address]);
  return (
    <>
      {!isConnected ? (
          <AuthPage />
      ) : (
        <Container>
        <ChatComponent />
        <NavBarRenderer/>
        </Container>
      )}
      </>
  );
}
